<template>
  <DefaultTable searchable v-if="files.length" :columns="cols" :rows="files" :initialSortBy="sortingProperties">
    <template #preview="data">
      <FilePreview :file-name="data.item.name" :title="data.item.title" :thumbnail="data.item.thumbnail"/>
    </template>

    <template #actions="data">
      <UploadedFileActions @edit="onEdit" :file="data.item" />
    </template>
  </DefaultTable>
  <h6 v-else class="mt-2">Keine Dateien vorhanden</h6>
</template>

<script>
import { events } from "@/constants.js";
import FilePreview from "@/components/FilePreview.vue";
import UploadedFileActions from "@/components/UploadedFileActions.vue";
import DefaultTable from "@/components/DefaultTable.vue";

export default {
  name: "UploadedFileTable",
  emits: [events.EDIT],
  props: {
    modelValue: {
      type: Array,
      default: null
    }
  },
  computed: {
    files: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit(events.UPDATE_MODEL_VALUE, value);
      }
    },
    cols() {
      return [
        { label: "Vorschau", field: "previews", type: "preview", sortable: false },
        { label: "Erstellt am", field: "createdAt", type: "defaultDate" },
        { label: "Format", field: "format" },
        { label: "Name", field: "title", tdClass: this.$style.title },
        { label: "", field: "actions", type: "actions", sortable: false }
      ];
    },
    sortingProperties() {
      return [
        { field: "createdAt", type: "desc" }
      ];
    }
  },
  methods: {
    onEdit(file) {
      const index = this.files.findIndex((f) => f.id == file.id);
      this.files[index] = file;
    }
  },
  components: {
    FilePreview,
    UploadedFileActions,
    DefaultTable
  }
};
</script>

<style module lang="scss">
  .title {
    max-width: 15rem;
    word-break: break-all;
  }
</style>
