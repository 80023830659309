<template>
  <Spinner v-if="files === null" />
  <div v-else>
    <UploadedFileTable v-if="showAll" v-model="files"/>
    <UploadedFileSortableList v-else :belongsToCompany="belongsToCompany" v-model="recentFiles"/>
  </div>
</template>

<script>
import { events } from "@/constants.js";
import Spinner from "@/components/Spinner.vue";
import UploadedFileTable from "@/components/UploadedFileTable.vue";
import UploadedFileSortableList from "@/components/UploadedFileSortableList.vue";

export default {
  name: "UploadedFileList",
  emits: [events.EDIT],
  data() {
    return {
      recentFiles: this.getRecentFiles()
    };
  },
  props: {
    modelValue: {
      type: Array,
      default: null
    },
    belongsToCompany: {
      type: Boolean,
      default: false
    },
    showAll: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    files: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit(events.UPDATE_MODEL_VALUE, value);
      }
    }
  },
  methods: {
    getRecentFiles() {
      const files = [...this.modelValue];
      files.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      return files.slice(0, 10);
    }
  },
  watch: {
    modelValue: {
      handler() {
        this.recentFiles = this.getRecentFiles();
      },
      deep: true
    }
  },
  components: {
    Spinner,
    UploadedFileTable,
    UploadedFileSortableList
  }
};
</script>

<style module lang="scss">

</style>
